<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Folder Rack" icon="folder"/>

    <div class="mainBoxBorderless mainBox60">

        <!-- <div class="text-info small">
            <div>total: {{total}}</div>
            <div v-for="p in projects" :key="p">- {{p.title}} - {{p.projectId}} - {{p.isFavourite}}</div>
        </div>
        <div class="text-danger small">
            filterStatus = {{filterStatus}}<br> filterFav = {{filterFav}}<br />
            filterMode = {{filterMode}}<br />filterSignMode = {{filterSignMode}}<br /> filterPrivacy = {{filterPrivacy}} <br>
            filterName = {{filterName}} <br /> <span class="text-warning">total: {{totalFilter}}</span>
        </div> -->
        
        <div class="row mb-3">
            <div class="col-12 col-md-8 col-lg-10">
                <div v-if="total === 0">
                    Good 
                    <span v-if="hour >= 5 && hour < 12">morning</span>
                    <span v-if="hour >= 12 && hour <= 18">afternoon</span>
                    <span v-if="(hour >= 18 && hour <= 23) || (hour >= 0 && hour < 5)">evening</span>
                    {{ getDisplayName }}, you may create your first folder now.
                </div>
            </div>
            <div class="col-12 col-md-4 col-lg-2 text-end">
                <span @click="$router.push({ path: '/uploadDoc' })" class="text-primary isLink">
                    <fa icon="plus" class="me-2"/>Add folder
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-12 mt-4">

                <div class="d-flex flex-wrap justify-content-between">
                    <span class="filter-pill-border">
                        <span class="filter-item-pill" :class="filterStatus === '' ? 'active' : ''" @click="filterStatus = ''">ALL</span>
                        <span class="filter-item-pill" :class="filterStatus === 'WIP' ? 'active' : ''" @click="filterStatus = 'WIP'">WIP</span>
                        <span class="filter-item-pill" :class="filterStatus === 'DONE' ? 'active' : ''" @click="filterStatus = 'DONE'">DONE</span>
                        <span class="filter-item-pill" :class="filterStatus === 'CANCELED' ? 'active' : ''" @click="filterStatus = 'CANCELED'">CANCELED</span>
                    </span>

                    <span class="filter-border">
                        <span class="filter-item-star" :class="filterFav ? 'active' : ''" @click="filterFav = !filterFav"><fa icon="star" /></span>
                    </span>

                    <span class="filter-border">
                        <span class="filter-item pe-3" :class="filterMode === 'SIMPLE' ? ' active' : ''" @click="filterMode = 'SIMPLE'; filterSignMode = ''">SIMPLE</span>
                        <span class="filter-item pe-3" :class="filterMode === 'KYC' ? ' active' : ''" @click="filterMode = 'KYC'">KYC</span>
                        <span class="filter-item" :class="filterMode === 'STRICT' ? ' active' : ''" @click="filterMode = 'STRICT'">STRICT</span>
                    </span>

                    <span class="filter-border">
                        <span v-if="(filterMode === 'SIMPLE' || filterMode === '')" 
                            class="filter-item disabled">
                            <fa icon="eye" />
                        </span>
                        <span v-if="(filterMode === 'KYC' || filterMode === 'STRICT') && (filterSignMode === '' || filterSignMode === 'PROJECT')" 
                            class="filter-item" :class="filterMode === 'SIMPLE' || filterSignMode === '' ? 'disabled' : 'active'" 
                            @click="filterSignMode = 'DOCUMENT'" style="cursor: pointer">
                            <Popper class="popperDark" arrow hover content="Full Access"><fa icon="eye" /></Popper>
                        </span>
                        
                        <span v-if="filterSignMode === 'DOCUMENT'" @click="filterSignMode = 'PROJECT'"
                            class="filter-item" :class="filterMode === 'SIMPLE' || filterSignMode === '' ? 'disabled' : 'active'">
                            <Popper class="popperDark" arrow hover content="Restrict"><fa icon="eye-slash"  /></Popper>
                        </span>
                        
                    </span>

                    <span class="filter-border">
                        <span class="filter-item pe-3" :class="filterPrivacy === 'PUBLIC' ? 'active' : ''" @click="filterPrivacy = 'PUBLIC'">PUBLIC</span>
                        <span class="filter-item pe-3" :class="filterPrivacy === 'PRIVATE' ? 'active' : ''" @click="filterPrivacy = 'PRIVATE'">PRIVATE</span>
                        <span class="filter-item" :class="filterPrivacy === 'CONFIDENTIAL' ? 'active' : ''" @click="filterPrivacy = 'CONFIDENTIAL'">CONFIDENTIAL</span>
                    </span>
                </div>

                <div class="d-flex flex-wrap justify-content-start">

                    <span class="filter-border border-0 p-0 me-2">
                        <div class="input-group">
                            <span class="input-group-text"><fa icon="search" /></span>
                            <input type="text" v-model="filterName" class="form-control" placeholder="Folder Name / Document Name" style="width: 290px;" @dblclick="delfilterName">
                        </div>
                        <!-- <div class="input-group">
                            <input type="text" class="form-control" v-model="filterName" placeholder="Folder Name / Document Name" style="width: 290px;" @dblclick="delfilterName">
                            <button class="btn btn-primary" type="button" @click="filterProjName">
                                <Popper class="popperDark" arrow hover content="Search">
                                <fa icon="search" />
                                </Popper>
                            </button>
                        </div> -->
                    </span>

                    <span class="filter-border">
                        <fa v-if="totalFilter === 0" icon="times" class="me-2" />
                        <span v-else class="badge bg-primary rounded-pill me-1">{{totalFilter}}</span>
                        <span class="filter-item" @click="resetSearch">Clear</span>
                    </span>
                </div>

            </div>
        </div>

    </div><!-- mainBoxBorderless -->

    <div v-if="total > 0" class="nextBox nextBox60 border shadow-sm">

      <div class="row">
            <div class="col-12">
                <div v-if="isLoading" class="mb-3 text-center">
                    <div class="spinner-border spinner-border-sm me-1"></div> Loading...
                </div>  

                <div class="accordion" id="myAccordion" ref="scrollComponent">

                    <div class="accordion-item" v-for="(proj, pIdx) in projects" :key="proj.projectId">

                        <div class="accordion-header" :id="'b_' + pIdx">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#bc_' + pIdx" aria-expanded="false" :aria-controls="'bc_' + pIdx">

                                <span style="width: 90%">
                                    <router-link :to="proj.status === 'DRAFT' ? '/uploadDoc/' + proj.projectId : '/project/' + proj.projectId">    
                                        <!-- Favourite -->
                                        <span class="isLink" style="width: 120px">
                                            <span v-if="proj.isFavourite === true" class="star">
                                                <Popper class="popperDark" arrow hover content="Click to remove from favorite">
                                                    <fa icon="star" size="lg" color="orange" @click.prevent="updFavorite(proj.projectId, pIdx, false)"/>
                                                </Popper>
                                            </span>
                                            <span v-if="proj.isFavourite === false || proj.isFavourite === undefined" class="star">
                                                <Popper class="popperDark" arrow hover content="Click to add to favorite">
                                                    <fa icon="star"  size="lg" @click.prevent="updFavorite(proj.projectId, pIdx, true)" class="text-grey"/>
                                                </Popper>
                                            </span>
                                        </span>

                                        <span v-if="proj.mode === 'SIMPLE'" class="isTooltipsLabel me-1">
                                            <Popper class="popperDark" arrow hover content="SIMPLE">
                                            <fa icon="square" size="lg" class="text-grey" />
                                            </Popper>
                                        </span>
                                        <span v-if="proj.mode === 'KYC'" class="isTooltipsLabel me-1">
                                            <Popper class="popperDark" arrow hover content="KYC">
                                            <fa icon="cube" size="lg" class="text-grey" />
                                            </Popper>
                                        </span>
                                        <span v-if="proj.mode === 'STICT'" class="isTooltipsLabel me-1">
                                            <Popper class="popperDark" arrow hover content="STRICT">
                                            <fa icon="cubes" size="lg" class="text-grey" />
                                            </Popper>
                                        </span>

                                        <span v-if="proj.signMode !== null" class="isTooltipsLabel me-1">
                                            <span v-if="proj.signMode.toUpperCase() === 'PROJECT'" >
                                            <Popper class="popperDark" arrow hover content="Full Access">
                                                <fa icon="eye" size="lg" class="text-grey"/>
                                            </Popper>
                                            </span>
                                            <span v-else class="isTooltipsLabel">
                                            <Popper class="popperDark" arrow hover content="Restrict">
                                                <fa icon="eye-slash" size="lg" class="text-grey"/>
                                            </Popper>
                                            </span>
                                        </span>

                                        <span v-if="proj.title" class="fw-bold me-1 greyLink" style="font-size: 14px">{{ proj.title }}</span>
                                        <span v-else class="fw-bold pe-1 greyLink" style="font-size: 14px">- </span>
                                        <Popper class="popperDark" arrow hover content="Total Document">
                                            <span class="badge bg-grey me-1 isTooltipsLabel">{{proj.totalDoc}}</span>
                                        </Popper>

                                        <ProjStatus :status="proj.status" />
                                        <ProjPrivacy :value="proj.privacy" />

                                        <span class="badge tag isLabel" v-for="t in proj.tags" :key="t">
                                            <fa icon="hashtag" class="pe-1"/>{{ t }}
                                            <!-- Small screen -->
                                            <span class="d-lg-none">
                                            <a href="#" class="ms-1" @click.prevent="delTag(proj.projectId, proj.tags, t)"><fa icon="xmark" /></a>
                                            </span>
                                            <!-- Big screen -->
                                            <span class="tagClose" @click.prevent="delTag(proj.projectId, proj.tags, t)">
                                            <fa icon="xmark" />
                                            </span>
                                        </span>
                                        <span class="float-end isTooltipsLabel greyLink">
                                            <Popper class="popperDark" arrow hover :content="func.convDateTimeFormat(proj.dateCreate, 'datetime')">
                                                <span class="isTooltipsLabel" style="font-size: 12px;"><i>{{ func.getDateTimeDiff(func.convDateTimeFormat(proj.dateCreate), new Date()) }}</i></span>
                                            </Popper>
                                        </span>    

                                    </router-link>
                                    
                                </span>

                            </button>
                            
                        </div>

                        <div :id="'bc_' + pIdx" class="accordion-collapse collapse" :aria-labelledby="'b_' + pIdx">
                            <div class="accordion-body">
                                <span v-for="(doc, docIdx) in proj.documents" :key="doc.documentId">
                                    <span v-if="docIdx < 3 " 
                                    @click.prevent="router.push('/pdf/view/'+proj.projectId+'/'+doc.documentId)" 
                                    class="d-inline-block text-truncate border rounded-pill me-1 px-2 py-1 docPill isLink">
                                        <fa icon="file-pdf" class="me-1" size="lg" />{{doc.name.replace('.pdf', '')}}
                                    </span>
                                    <span v-if="docIdx === 3" class="px-2 py-1 d-inline-block text-truncate border rounded-pill">{{ proj.documents.length - 3 }}+</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    
                </div><!-- accordion end -->

                <div v-if="isLoadingMore" class="mt-3 text-center">
                    <div class="spinner-border spinner-border-sm me-1"></div> Loading...
                </div>
            </div>
      </div>

    </div>

</template>

<script>
import { ref, inject, watch, onMounted, onUnmounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import Popper from 'vue3-popper'
import { Tooltip } from 'bootstrap/dist/js/bootstrap.js'
import { useStore } from '@/stores/store'
import funcs from '@/functions/function'
import { useRouter } from 'vue-router'
import ProjStatus from '@/components/ProjStatus.vue'
import ProjPrivacy from '@/components/ProjPrivacy.vue'

export default {
    name: 'ProjectListBk',
    components: {
        Alert, TopNavigation, Popper, Tooltip, ProjStatus, ProjPrivacy
    },
    setup() {
        const axios = inject('axios')
        const alert = ref([])
        const projects = ref([])

        const router = useRouter()

        const func = funcs
        const hour = ref(null)

        const totalFilter = ref(0)
        const filterName = ref('')
        const filterStatus = ref('') // /WIP/DONE/CANCELED
        const filterFav = ref(false)
        const filterMode = ref('') // SIMPLE/KYC/STRICT
        const filterSignMode = ref('') // PROJECT/DOCUMENT (Full Access/Restrict) - (KYC/STRICT Only)
        const filterPrivacy = ref('') // PUBLIC/PRIVATE/CONFIDENTIAL

        const projectFilterParams = ref({})
        const total = ref(10)

        const scrollComponent = ref(null)
        const rcdFr = ref(0) // record index from 
        const rcdDisplay = ref(5) // total record to display, ORIG: 15
        const isLoading = ref(true)
        const isLoadingMore = ref(false)
        const isStar = ref(false)
        const isAll = ref(true)

        const { getDisplayName } = useStore()

        const d = new Date()
        hour.value = d.getHours()

        watch([filterName, filterStatus, filterFav, filterMode, filterSignMode, filterPrivacy], () => {
            rcdFr.value = 0
            projects.value = []

            console.info('WATCH', 'filterName:', filterName.value, 'filterStatus:', filterStatus.value, 'filterFav:', filterFav.value, typeof filterFav.value, 'filterMode:', filterMode.value, 'filterSignMode:', filterSignMode.value, 'filterPrivacy:', filterPrivacy.value)
            totalFilter.value = 0

            if (filterName.value !== '' && filterName.value.length > 2) { 
                totalFilter.value++
            }
            if (filterStatus.value !== '') {
                totalFilter.value++
            }
            if (filterFav.value === true) {
                totalFilter.value++
            }

            if (filterMode.value !== '') {
                totalFilter.value++
            }
            if (filterSignMode.value !== '') {
                totalFilter.value++
            }

            if (filterPrivacy.value !== '') {
                totalFilter.value++
            }

            getProjList(false)
        })

        const getProjList = async (loadMore) => {
            // console.info('------------------------ getProjList', rcdFr.value)

            projectFilterParams.value = {}

            if (totalFilter.value > 0) {
                if (filterName.value !== '' && filterName.value.length > 2) { 
                    projectFilterParams.value.name = filterName.value
                }

                if (filterStatus.value !== '') {
                    projectFilterParams.value.status = filterStatus.value
                }

                if (filterFav.value === true) {
                    projectFilterParams.value.isFavourite = filterFav.value
                }
                
                if (filterMode.value !== '') {
                    projectFilterParams.value.mode = filterMode.value
                }

                if (filterSignMode.value !== '') {
                    projectFilterParams.value.signMode = filterSignMode.value
                }

                if (filterPrivacy.value !== '') {
                    projectFilterParams.value.privacy = filterPrivacy.value
                }

            }

            projectFilterParams.value.orderBy = [
                {
                    field: 'name',
                    order: 'asc'
                }
            ]
            projectFilterParams.value.limit = rcdFr.value + ',' + rcdDisplay.value

            console.info('params', JSON.stringify(projectFilterParams.value))

            await axios.post('/signon/list', projectFilterParams.value)
            .then((res) => {

                // console.info('getProj ' + res.data.data.length, JSON.stringify(res.data))
                console.info('getProj ' + res.data.data.length)

                if (loadMore) {
                    console.info('load more here')
                    isLoadingMore.value = false
                } else {

                    console.info('x load more here')
                    isLoading.value = false
                }

                if (res.data.status === 1) {
                    if (res.data.data.length > 0) {
                        projects.value.push(...res.data.data)

                        projects.value.forEach((item, index) => {
                            getProjDoc(item.projectId, index)
                        })
                    }
                } 
            })
            .catch((error) => {
                console.log('ProjectList | ERR', JSON.stringify(error))
            })

        }

        const loadMoreProj = () => {
            // isLoadingMore.value = true

            if (rcdFr.value < total.value) {
                rcdFr.value = rcdFr.value + rcdDisplay.value
                getProjList(true)
                // isLoadingMore.value = false

            } else {
                isLoadingMore.value = false
            }
        }

        const getProjDoc = (id, index) => {
            let totalDoc = 0
            axios.get( '/signon/' + id)
            .then((res) => {
                if (res.data.status === 1) {
                    projects.value[index].documents = []
                    projects.value[index].totalDoc = 0 // Excluded REMOVED doc

                    if (res.data.data.documents.length > 0) {
                        res.data.data.documents.forEach((item, idx) => {

                            if (item.status !== 'REMOVED') {
                               projects.value[index].documents.push(item)
                               totalDoc++
                            }
                        })
                        projects.value[index].totalDoc = totalDoc
                    }
                    // console.info('---', JSON.stringify(res.data.data.documents))
                } 
            })
            .catch((error) => {
                console.log('getProjDoc | FAILURE', JSON.stringify(error))
            })
        }

        const delfilterName = () => {
            projects.value = []
            filterName.value = ''
            getProjList(null)
        }

        const resetSearch = () => {

            totalFilter.value = 0

            filterStatus.value = ''
            filterFav.value = false
            filterMode.value = ''
            filterSignMode.value = ''
            filterPrivacy.value = ''
            filterName.value = ''

            rcdFr.value = 0
            projects.value = []
        }

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const getTotalProj = () => {
            axios.post('/signon/list', {})
            .then((res) => {

                if (res.data.status === 1) {
                    total.value = res.data.data.length
                } 
            })
            .catch((error) => {
                console.log('getTotalProj | ERR', JSON.stringify(error))
            })
        }

        onMounted(() => {

            // initial tooltip
            Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]'))
            .forEach(tooltipNode => new Tooltip(tooltipNode))

            Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]'))
            .forEach(tooltipNode => new Tooltip(tooltipNode))

            window.addEventListener("scroll", handleScroll)
            func.clearPrevLocalStorage()

            rcdFr.value = 0
            projects.value = []
            getTotalProj()
            getProjList(false)

            // Clear document selection in Signatory
            localStorage.removeItem('documentId')
            localStorage.removeItem('documentName')
            localStorage.removeItem('documentUrl')

            // Clear signee selection in Signatory
            localStorage.removeItem('signeeClass')
            localStorage.removeItem('signeeId')
            localStorage.removeItem('signeeName')
            localStorage.removeItem('signeeEmail')
            localStorage.removeItem('signee')
        })

        onUnmounted(() => {
            window.removeEventListener("scroll", handleScroll)
        })

        const handleScroll = (e) => {
            
            let element = scrollComponent.value
            if (element !== null) {
                // console.info('handleScroll', element.getBoundingClientRect().bottom.toFixed(0), window.innerHeight)
                if (element.getBoundingClientRect().bottom.toFixed(0)*1 < window.innerHeight) {
                    isLoadingMore.value = true
                    loadMoreProj()
                }
            }
        }

        const updFavorite = (id, idx, f) => {
            const p = { isFavourite: f }

            axios.put( '/signon/' + id, p)
            .then((res) => {
                if (res.data.status === 1) {
                    projects.value[idx].isFavourite = f
                } 
            })
            .catch((error) => {

                console.log('updFavorite | ERR', JSON.stringify(error))
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: func.convError(error)
                })
            })
        }

        const delTag = async (id, ptags, val) => {

            let tmpTags = []
            ptags.forEach((item, idx) => {
                if (item !== val) {
                    tmpTags.push(item)
                }
            })

            const p = {
                tags: tmpTags
            }

            axios.put( '/signon/' + id, p
            ).then((res) => {
                if (res.data.status === 1) {
                    
                    projects.value = []
                    getProjList('', '')

                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Tag is added."
                    })
                } 
            })
            .catch((error) => {

                console.log('delTag | FAILURE', JSON.stringify(error))
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: func.convError(error)
                })
            })
        }

        return {
            func, alert, closeAlert, router, isLoading, isLoadingMore, getDisplayName, 
            scrollComponent, hour, total, rcdFr, rcdDisplay, projects, 
            getProjDoc, updFavorite, delfilterName, delTag, loadMoreProj, 
            total, projects, totalFilter, filterName, filterStatus, filterFav, filterMode, filterSignMode, filterPrivacy, resetSearch, 

            isStar, isAll
        }

    } // setup
}
</script>

<style>
  body {
      color: #6E777E;
      font-size: 14px;
      overflow: auto;
  }
  .statusBox {
    width: 100px;
    border-radius: 5px;
  }

  .star {
      font-size: 14px;
      padding: 5px; 
  }
  .star:hover {
      background-color: #DADADB;
      padding: 5px;
      padding-bottom: 7px;
      padding-right: 5px;
      border-radius: 20px;
  }


  .starFilter:hover {
      background-color: #DADADB;
      padding: 5px;
      padding-bottom: 7px;
      padding-right: 5px;
      border-radius: 20px;
  }

  .allFilter:hover {
      background-color: #DADADB;
      padding: 5px 10px;
      border-radius: 10px;
  }

  .allFilterSelected {
      font-weight: bold;
  }

  .docPill:hover {
      background-color: var(--doc-pill-hover);
  }

  .boxStatus, .boxStatusTop {
      border-radius: 10px;
      padding: 15px 25px;
      margin-left: 4px;
      margin-right: 4px;
  }

  @media (max-width: 500px) {
      .boxStatusTop {
          margin-bottom: 20px;
      }
  }

  .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    /* background-color: var(--accordion-bg); */
    background-color: var(--accordion-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  background-color: var(--accordion-bg);
}

.accordion-button:focus {
  z-index: 3;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.125);
}

.accordion-header {
  margin-bottom: 0;
  font-size: 14px;
  background-color: var(--accordion-bg);
}

.accordion-item {
  margin-bottom: 10px;
  border: 1px solid var(--accordion-border);
}

.accordion-item {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-item .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-item:last-of-type {
  margin-bottom: 0px;
}

.accordion-body {
  padding: 1rem 1.25rem;
  background-color: var(--bgcolor-body-color);
}

.filter-border {
    border: 1px solid var(--accent-color);
    border-radius: 5px; 
    font-size: 1rem; 
    padding: 7px 15px;
    /* margin-right: 10px; */
    margin-bottom: 10px;
}

.filter-item {
    cursor: pointer;
}

.filter-item:hover {
    color: var(--bs-primary);
}

.filter-item:active, .filter-item.active {
    color: #53BDEB;
    cursor: pointer;
}

.filter-item:disabled, .filter-item.disabled {
    color: var(--toolbar-hr);
    cursor: default;
}

.filter-item-star {
    cursor: pointer;
}

.filter-item-star:hover {
    color: var(--bs-primary);
}

.filter-item-star:active, .filter-item-star.active {
    color: #53BDEB;
}

.filter-item-star:disabled, .filter-item-star.disabled {
    color: var(--toolbar-hr);
    cursor: default;
}

.filter-pill-border {
    border: 1px solid var(--accent-color);
    border-radius: 5px; 
    font-size: 1rem; 
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 10px;
}

.filter-item-pill {
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
}

.filter-item-pill:hover {
    color: var(--bs-primary);
}

.filter-item-pill:active, .filter-item-pill.active {
    color: var(--bgcolor-body-color);
    background-color: #53BDEB;
    border-radius: 5px;
    padding: 9px 15px;
    cursor: default;
    margin: 0px;
}

.filter-item-pill:disabled, .filter-item-pill.disabled {
    color: var(--toolbar-hr);
    border-radius: 5px;
    padding: 9px 15px;
    cursor: default;
    margin: 0px;
}

.btn-primary {
  color: var(--bgcolor-body-color);
  background-color: #53BDEB;
  border-color: #53BDEB;
}

.btn-primary:hover {
  color: var(--bgcolor-body-color);
  background-color: #46ABD7;
  border-color: #46ABD7;
}

.input-group-text {
    background-color: var(--bs-primary);
    color: var(--bgcolor-body-color);
    padding-top: 11px;
    padding-bottom: 11px;
}
</style>